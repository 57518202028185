import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper,
    Typography, Checkbox,
} from "@material-ui/core";
import {getFLBaselineTreat} from "../../query/baseline";
import PanelDivider from "../PanelDivider";
import {PaperColorSelect, SelectType} from "../PaperColor";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArticlesListEx from "../article/ArticlesListEx";
import SelTextView from "../SelTextView";
import {withStyles} from "@material-ui/core/styles";
import {capitalizeFirstLetter, getQueryResult, getServiceMessages} from "../../utils";
import {FormattedMessage} from "react-intl";
import ReactMarkdown from "react-markdown";
import config from "../../config";
import ServiceCheckbox from "../button/ServiceCheckbox";
import {Message, ServiceMessage, DrugCompStatus} from "../../const";
import {MessageType} from "../../const";
import {TypographySubtitle} from "../../pages/CommonElements";
import {InfoHelp} from "../common/InfoHelp";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";
import ExpandChartsPanel from "./ExpandChartsPanel";

const ArticlesPanel = withStyles(theme => ({
    root: {
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(Accordion);

const ArticlesPanelSummary = withStyles(theme => ({
    root: {
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    expanded: {},
    content: {
        margin: '0',
        '&$expanded': {
            margin: '0',
        },
    },
}))(AccordionSummary);

export default class TherapyTreats extends React.Component {

    abortController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const {touched, updateCompStat} = this.props;

        if (touched) {
            this.loadAllBaselines()();
        } else {
            updateCompStat(() => {
                this.updateViewContextPanel();
                this.checkSelectTherapy();
            });
        }

        this.forestPlotView();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    loadAllBaselines = () => (callback) => {
        const {getThNames, getTherapyData} = this.props;

        if (getThNames !== undefined) {
            getThNames()
                .filter(th => !Boolean(th.isSimple))
                .filter(th => {
                    const therapy = getTherapyData(th.name);
                    return !(Boolean(therapy) && Boolean(therapy.data));
                })
                .forEach((th, index, array) => this.loadBaselineTreat(th.name, (index + 1 === array.length) ? callback : undefined));
        }
    };

    loadBaselineTreat = (therapyName, callback) => {
        const {getTherapyWithName, getFeatures, getTherapyCustomTreatments, updateCompStat} = this.props;
        const features = getFeatures();
        const therapy = getTherapyWithName(therapyName);

        if (therapy === undefined || therapy.active === -1) {
            return;
        }

        const customTreatments = getTherapyCustomTreatments(therapyName);

        const {client, getTherapyData, getSelReferent, activePatient} = this.props;
        const {signal} = this.abortController;
        const {data: {treatments} = {}} = getTherapyData(therapyName);
        let inTreats = [];

        if (Boolean(treatments)) {
            inTreats = treatments.filter(tr => {
                if (Boolean(customTreatments) && customTreatments.length > 0
                    && !customTreatments.includes(tr.name)) {
                    return false;
                }
                return tr.isSel;
            }).map(tr => tr.name);
        } else if (Boolean(customTreatments) && customTreatments.length > 0) {
            inTreats = customTreatments;
        }

        const inRefer = getSelReferent(therapyName);
        const patientId = Number(activePatient.patient) || 0;

        client.query({
            query: getFLBaselineTreat,
            variables: {
                patientId: patientId,
                therapyName: therapyName,
                features: features,
                groups: therapy.sgNames,
                inTreats: inTreats,
                inRefer: inRefer ? inRefer.name : null,
            },
            context: {
                uri: config.options.server.fl_url + config.options.server.api_uri,
                fetchOptions: {
                    signal,
                },
            },
        })
            .then(result => getQueryResult(result?.data, 'getFLBaselineTreat'))
            .then(baselineTreat => {
                const therapy = getTherapyData(therapyName);
                if (Boolean(baselineTreat) && Boolean(therapy)) {
                    therapy['data'] = baselineTreat;

                    updateCompStat(() => {
                        this.updateViewContextPanel();
                        // this.checkSelectTherapy();
                    });
                }

                this.forestPlotView();
                if (!!callback) {
                    callback();
                }

            });
    };

    getTherapyIndex = (therapyName) => {
        const {getThData} = this.props;

        if (getThData !== undefined) {
            const thData = getThData();
            if (Boolean(thData)) {
                return thData.findIndex(th => th.name === therapyName);
            } else {
                return -1;
            }
        }

        return -1;
    };

    // getTreatmentsAvailable = (therapyName) => {
    //   const { getTherapyData } = this.props;
    //   const thData = getTherapyData(therapyName);
    //   if (!Boolean(thData)) {
    //     return [];
    //   }
    //
    //   const {data: {treatments} = {}} = thData;
    //   if (!Boolean(treatments)) {
    //     return [];
    //   }
    //
    //   const {getTherapyCustomTreatments} = this.props;
    //   const customTreatments = getTherapyCustomTreatments(therapyName);
    //
    //   return treatments
    //     .filter(tr => !(Boolean(customTreatments) && customTreatments.length > 0
    //       && !customTreatments.includes(tr.name)));
    // };

    getTreatmentsAvailable = (therapyName) => {
        const {getTherapyData} = this.props;
        const thData = getTherapyData(therapyName);
        if (!Boolean(thData)) {
            return [];
        }

        const {data: {treatments} = {}} = thData;
        if (Boolean(treatments)) {
            return treatments;
        }

        return [];
    };

    getGroupsTreatmentsAvailable = (therapyName) => {
        const {getTherapyData} = this.props;
        const thData = getTherapyData(therapyName);
        if (!Boolean(thData)) {
            return [];
        }

        const {data: {groups} = {}} = thData;
        if (Boolean(groups)) {
            return groups.sort((item1, item2) => item1.ord - item2.ord);
        }

        return [];
    };

    getTherapyMessages = (therapyName): Message[] => {
        const {getSelectLine} = this.props;
        const {sgTreatments} = getSelectLine();
        if (sgTreatments === undefined) {
            return [];
        }

        const therapy = sgTreatments.find(tr => tr.name === therapyName && Boolean(tr.messages))
        if (therapy === undefined || therapy.messages === undefined) {
            return [];
        }

        return therapy.messages;
    }

    updateCharts = (therapyName) => () => {
        this.loadBaselineTreat(therapyName);
    };

    onTreatChange = (therapyName, treatId) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const therapy = this.props.getTherapyWithName(therapyName);
        const treatments = this.getTreatmentsAvailable(therapyName);
        if (!Boolean(treatments)) {
            return;
        }

        // check is referent
        if (Boolean(therapy) && Boolean(therapy.referent)
            && therapy.referent.toLowerCase() === treatments[treatId].name.toLowerCase()) {
            return;
        }

        const selCount = treatments.filter(t => t.isSel).length;

        if (selCount <= 2 && !e.target.checked) {
            return;
        }

        for (let treat of treatments) {
            if (treat.id === treatId) {
                treat.isSel = e.target.checked;
            }
        }

        for (let i = 0; i < treatments.length; ++i) {
            if (i === treatId) {
                treatments[i].isSel = e.target.checked;
            }
        }

        const therapyIndex = this.getTherapyIndex(therapyName);
        if (therapyIndex !== -1) {
            const {setSelThId} = this.props;
            if (setSelThId !== undefined) {
                setSelThId(therapyIndex);
            }
        } else {
            this.forceUpdate();
        }
    };

    isChecked = (therapyName, treatName): boolean => {
        const {getThNames} = this.props;

        const therapy = getThNames().find(tr => tr.name === therapyName);

        if (!Boolean(therapy)) {
            return false;
        }

        return Boolean((therapy.treatments || {})[treatName]);
    }

    onSimpleTreatChange = (therapyName, treatName) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const {getThNames} = this.props;

        const therapy = getThNames().find(tr => tr.name === therapyName);
        if (!Boolean(therapy)) {
            return;
        }

        const selCount = Object.keys(therapy.treatments).filter(t => therapy.treatments[t]).length;

        if (selCount < 2 && !e.target.checked) {
            return;
        }

        therapy.treatments[treatName] = e.target.checked;
        const therapyIndex = this.getTherapyIndex(therapyName);
        if (therapyIndex !== -1) {
            const {setSelThId} = this.props;
            if (setSelThId !== undefined) {
                setSelThId(therapyIndex);
            }
        } else {
            this.forceUpdate();
        }

        // TODO: добавил для перерисовки графиков
        this.forestPlotView();
    };

    onSkipDrugSelect = (therapy, groupsTreatments) => (e) => {
        const {
            isPrescriptionReadonly,
            onSkipDrugSelect,
        } = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        if (e.target.checked) {
            onSkipDrugSelect(therapy, groupsTreatments);
        }
    };

    onReferenceChange = (therapy, groupTreatmentsId) => (e) => {
        const {isPrescriptionReadonly, setSelReferentId, getSelectLine, getTherapyWithName} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        setSelReferentId(therapy.name, Number(e.target.value), groupTreatmentsId, e?.target?.checked);
        therapy.skipDrugSelect = false;

        const therapyIndex = this.getTherapyIndex(therapy.name);
        if (therapyIndex !== -1) {
            const {setSelThId} = this.props;
            if (setSelThId !== undefined) {
                const therapyWithName = getTherapyWithName(therapy.name);
                setSelThId(therapyIndex, therapyWithName, true);
            }
        } else {
            this.forceUpdate();
        }

        // TODO: добавил для перерисовки графиков
        this.forestPlotView();
    };

    getTreatmentsView = (therapy) => {
        const {
            classes,
            getIsSelThId,
            getSelThId,
            setSelThId,
            isTherapyActive,
            getTherapyFullname,
            getTherapyWithName,
            getTherapyData,
            intl,
            isPrescriptionReadonly,
            getSelectLine,
            // getSelReferentId,
            // isTherapyRequired,
        } = this.props;
        let contentView;
        const thId = this.getTherapyIndex(therapy.name);

        // const therapyRequired = isTherapyRequired(therapy.name);

        const therapyWithName = getTherapyWithName(therapy.name);
        // console.error('+++---+++___+++ getTreatmentsView() +++ therapyWithName:', therapyWithName);
        // console.error('+++---+++___+++ getTreatmentsView() +++ therapy:', therapy);
        // console.error('+++---+++___+++ getTreatmentsView() +++ therapy.name:', therapy.name);
        // console.error('+++---+++___+++ getTreatmentsView() +++ isTherapyActive(therapy.name):', isTherapyActive(therapy.name));

        if (isTherapyActive(therapy.name)) {
            const {data: {params} = {}} = getTherapyData(therapy.name) || {};
            const groupsTreatments = this.getGroupsTreatmentsAvailable(therapy.name);
            // console.error('+++---+++___+++ getTreatmentsView() +++ groupsTreatments:', groupsTreatments);

            const therapyMessages = this.getTherapyMessages(therapy.name);

            const serviceWarningCheckBox = getServiceMessages(therapyMessages, MessageType.WARNING);
            const serviceSubtitleCheckBox: ServiceMessage = getServiceMessages(therapyMessages, MessageType.SUBTITLE);

            const naStr = intl.formatMessage({id: 'message.data.empty.short', defaultMessage: 'NA'});

            if (groupsTreatments.length === 0) {
                contentView = (<Box/>);
            } else {
                // console.error('+++---+++___+++ getTreatmentsView() +++ therapyWithName:', therapyWithName);
                // console.error('+++---+++___+++ getTreatmentsView() +++ getIsSelThId(thId, therapy.name, therapyWithName):', getIsSelThId(thId, therapy.name, therapyWithName));
                contentView = (<Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <FormControlLabel
                        control={
                            <ServiceCheckbox
                                color={"primary"}
                                // checked={thId === getSelThId()}
                                checked={getIsSelThId(thId, therapy.name, therapyWithName)}
                                value={thId}
                                onChange={(e) => {
                                    // console.error('+++ FULL e.target.checked:', e.target.checked);

                                    if (isPrescriptionReadonly) {
                                        e.preventDefault();
                                        return;
                                    }

                                    if (!!setSelThId) {
                                        setSelThId(thId, therapyWithName, e.target.checked);
                                    }

                                    // TODO: добавил для перерисовки графиков
                                    this.forestPlotView();
                                }}
                                showService={serviceWarningCheckBox.showService}
                                text={serviceWarningCheckBox.messages}
                                type={serviceWarningCheckBox.type}
                            />

                        }
                        label={<SelTextView>{getTherapyFullname(therapy.name, therapy.title)}</SelTextView>}
                    />
                    {serviceSubtitleCheckBox.showService &&
                        <TypographySubtitle component={'span'}>
                            {serviceSubtitleCheckBox.messages.map((message, index) =>
                                    <ReactMarkdown key={index} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={message.payload}/>
                            )}
                        </TypographySubtitle>
                    }
                    <Grid container>
                        {groupsTreatments
                            .map((groupTreatments) => {
                                return (
                                    <Grid item xs={12} key={groupTreatments.id}>
                                        <PanelDivider/>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <SelTextView>
                                                    {groupTreatments.name}
                                                </SelTextView>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <FormControl component='fieldset'>
                                                    {groupTreatments.treatments
                                                        .map((treatment) => {
                                                                let availableStatus = treatment.availableStatus !== undefined ? treatment.availableStatus : 0;
                                                                let dataAvailable = true;
                                                                // for (let treatment of treatments) {
                                                                //   if (treatment.name === rf.name) {
                                                                dataAvailable = treatment.dataAvailable;
                                                                //   }
                                                                // }
                                                                let isDrugAvailable = availableStatus === 0;
                                                                const serviceInfoHelp: ServiceMessage = getServiceMessages(treatment.messages, MessageType.INFO);
                                                                const compStatus = Boolean(treatment.compStatus) ? treatment.compStatus : 0;
                                                                let treatStyle;

                                                                const drugNaStr = ''; // dataAvailable ? '' : ' - '.concat(naStr);

                                                                // compStatus
                                                                if (!isDrugAvailable) {
                                                                    treatStyle = classes.drugNotAvailable;
                                                                } else {
                                                                    switch (compStatus) {
                                                                        case DrugCompStatus.WARNING:
                                                                            treatStyle = classes.drugWarning;
                                                                            break;
                                                                        case DrugCompStatus.DANGER:
                                                                            treatStyle = classes.drugDanger;
                                                                            break;
                                                                        default:
                                                                            treatStyle = classes.drugNormal;
                                                                    }
                                                                }

                                                                const serviceWarningCheckBox: ServiceMessage = getServiceMessages(treatment.messages, MessageType.WARNING);

                                                                return (
                                                                    <Grid container direction={"row"} key={treatment.id}>
                                                                        <Grid item>
                                                                            <FormControlLabel
                                                                                value={treatment.id}
                                                                                disabled={!isDrugAvailable}
                                                                                label={<Typography className={treatStyle}>
                                                                                    {`${treatment.name}${drugNaStr}`}
                                                                                </Typography>}
                                                                                control={
                                                                                    // <Radio color={'primary'} />
                                                                                    <ServiceCheckbox
                                                                                        color={"primary"}
                                                                                        checked={treatment.isSel}
                                                                                        value={treatment.id}
                                                                                        onChange={this.onReferenceChange(therapy, groupTreatments.id)}
                                                                                        showService={serviceWarningCheckBox.showService}
                                                                                        text={serviceWarningCheckBox.messages}
                                                                                        type={serviceWarningCheckBox.type}
                                                                                    />
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                        {serviceInfoHelp.showService &&
                                                                        <Grid item>
                                                                            <InfoHelp texts={serviceInfoHelp.messages}/>
                                                                        </Grid>
                                                                        }
                                                                    </Grid>
                                                                );
                                                            }
                                                        )
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            })
                        }

                        {/*{!therapyRequired &&*/}
                        {/*<Grid item xs={12}>*/}
                        {/*    <Grid container justifyContent='flex-end'>*/}
                        {/*        <Grid item>*/}
                        {/*            <FormControlLabel*/}
                        {/*                control={*/}
                        {/*                    <Checkbox*/}
                        {/*                        checked={!!therapy.skipDrugSelect}*/}
                        {/*                        color='primary'*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*                onChange={this.onSkipDrugSelect(therapy, groupsTreatments)}*/}
                        {/*                label={*/}
                        {/*                    <Typography*/}
                        {/*                        color={'primary'/*selGroup.selDrugId === -1 ? 'primary' : 'textPrimary'*!/>*/}
                        {/*                        <FormattedMessage id='label.drug.select.skip'*/}
                        {/*                                          defaultMessage='Skip drug selection'/>*/}
                        {/*                    </Typography>*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        </Grid>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        {/*}*/}

                    </Grid>

                    {params && params.length > 0 &&
                    <Box className={classes.contentBoxTopSpace}>
                        {params.map((p, id) => (
                            <Typography key={id} variant={"body2"}>
                                {`${p.key}: ${p.value}`}
                            </Typography>
                        ))}
                    </Box>
                    }

                </Box>);
            }
        } else {
            const therapyFullname = getTherapyFullname(therapy.name, therapy.title);
            const therapyNotReq = intl.formatMessage({
                id: 'label.bl.therapy_not_recoment',
                defaultMessage: 'Therapy is not required'
            });
            const therapyActiveTexts = this.props.getTherapyActiveTexts(therapy.name);

            if (therapyActiveTexts && therapyActiveTexts.length > 0) {
                contentView = (
                    <Box>
                        <Typography variant={"h6"}>
                            {`${therapyFullname}: ${therapyNotReq}`}
                        </Typography>
                        <Typography component={'span'}>
                            {therapyActiveTexts.map((txt, id) => (
                                <ReactMarkdown key={id} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                    children={txt} />
                            ))}
                        </Typography>
                    </Box>
               );
            } else {
                contentView = (
                    <Typography variant={"h6"}>
                        {`${therapyFullname}: ${therapyNotReq}`}
                    </Typography>
                );
            }
        }

        return (
            <PaperColorSelect
                className={classes.contentBox}
                // seltype={thId === getSelThId() ? SelectType.POSITIVE : SelectType.NONE}>
                seltype={getIsSelThId(thId, therapy.name, therapyWithName) ? SelectType.POSITIVE : SelectType.NONE}>
                {contentView}
            </PaperColorSelect>
        );
    };

    getSimpleTreatmentsView = (therapy) => {
        const {
            classes,
            getIsSelThId,
            getSelThId,
            setSelThId,
            isTherapyActive,
            getTherapyFullname,
            getTherapyWithName,
            intl,
            isPrescriptionReadonly
        } = this.props;
        let contentView;
        const thId = this.getTherapyIndex(therapy.name);
        const therapyWithName = getTherapyWithName(therapy.name);

        if (isTherapyActive(therapy.name)) {
            const treatments = Boolean(therapy.treatments) ? Object.keys(therapy.treatments) : [];
            const therapyMessages = this.getTherapyMessages(therapy.name);
            const serviceWarningCheckBox = getServiceMessages(therapyMessages, MessageType.WARNING);
            const serviceSubtitleCheckBox: ServiceMessage = getServiceMessages(therapyMessages, MessageType.SUBTITLE);

            contentView = (
                <Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <FormControlLabel control={
                        <ServiceCheckbox
                            color={"primary"}
                            // checked={thId === getSelThId()}
                            checked={getIsSelThId(thId, therapy.name, therapyWithName)}
                            value={thId}
                            onChange={e => {
                                if (isPrescriptionReadonly) {
                                    e.preventDefault();
                                    return;
                                }

                                if (setSelThId) {
                                    setSelThId(thId, therapyWithName, e.target.checked);
                                }
                            }}
                            showService={serviceWarningCheckBox.showService}
                            text={serviceWarningCheckBox.messages}
                            type={serviceWarningCheckBox.type}
                        />
                    } label={<SelTextView>{getTherapyFullname(therapy.name, therapy.title)}</SelTextView>}/>
                    {serviceSubtitleCheckBox.showService &&
                        <TypographySubtitle component={'span'}>
                            {serviceSubtitleCheckBox.messages.map((message, index) =>
                                <ReactMarkdown key={index} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]} children={message.payload}/>
                            )}
                        </TypographySubtitle>
                    }

                    {treatments && treatments.length > 0 && (
                        <Box>
                            <PanelDivider/>
                            <SelTextView>
                                <FormattedMessage id={'label.bl.refenent2'} defaultMessage={'Select drug:'}/>
                            </SelTextView>
                            <FormControl component='fieldset'>
                                <FormGroup>
                                    {treatments
                                        .map((tr, i) => {
                                            // const serviceInfoHelp: ServiceMessage = getServiceMessages(tr.messages, MessageType.INFO);
                                            const serviceInfoHelp: ServiceMessage = {
                                                showService: false,
                                                messages: [
                                                    {
                                                        payload: 'какой-то текст для ' + tr,
                                                        type: MessageType.INFO,
                                                    }
                                                ],
                                                type: MessageType.INFO,
                                            };
                                            return (
                                                <Grid container direction={"row"} key={i}>
                                                    <Grid item>
                                                        <FormControlLabel
                                                            // key={i}
                                                            label={capitalizeFirstLetter(tr)}
                                                            onChange={this.onSimpleTreatChange(therapy.name, tr)}
                                                            control={<ServiceCheckbox
                                                                checked={therapy.treatments[tr]}
                                                                color={"primary"}
                                                                // checked={this.isChecked(therapy.name, tr)}
                                                                value={tr}
                                                                showService={false}
                                                            />}
                                                        />
                                                    </Grid>
                                                    {serviceInfoHelp.showService &&
                                                    <Grid item>
                                                        <InfoHelp texts={serviceInfoHelp.messages}/>
                                                    </Grid>
                                                    }
                                                </Grid>
                                            );
                                        })}
                                </FormGroup>
                            </FormControl>
                        </Box>
                    )}
                </Box>
            );
        } else {
            const therapyFullname = getTherapyFullname(therapy.name, therapy.title);
            const therapyNotReq = intl.formatMessage({
                id: 'label.bl.therapy_not_recoment',
                defaultMessage: 'Therapy is not required'
            });
            const therapyActiveTexts = this.props.getTherapyActiveTexts(therapy.name);

            if (therapyActiveTexts && therapyActiveTexts.length > 0) {
                contentView = (
                    <Box>
                        <Typography variant={"h6"}>
                            {`${therapyFullname}: ${therapyNotReq}`}
                        </Typography>
                        <Typography component={"span"}>
                            {therapyActiveTexts.map((txt, id) => (
                                <ReactMarkdown key={id} remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw, rehypeSanitize]}
                                    children={txt} />
                            ))}
                        </Typography>
                    </Box>
                );
            } else {
                contentView = (
                <Typography variant={"h6"}>
                    {`${therapyFullname}: ${therapyNotReq}`}
                </Typography>);
            }
        }

        return (
            <PaperColorSelect
                className={classes.contentBox}
                // seltype={thId === getSelThId() ? SelectType.POSITIVE : SelectType.NONE}>
                seltype={getIsSelThId(thId, therapy.name, therapyWithName) ? SelectType.POSITIVE : SelectType.NONE}>
                {contentView}
            </PaperColorSelect>
        );
    };

    getPanelTitle = () => {
        const {getThNames, getTherapyFullname} = this.props;

        if (getThNames !== undefined) {
            return getThNames().map(it => getTherapyFullname(it.name, it.title)).join(' / ');
        } else {
            return '';
        }
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    setForestPlotView = (forestPlotView, callback) => {
        this.setState(prevState => ({
            forestPlotView: forestPlotView,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
        const {classes, intl} = this.props;

        const {getThNames, forestPlotCharts, getSelThId, isTherapyActive, isTherapyDatasetAvailable} = this.props;
        let thNames;

        if (getThNames !== undefined) {
            thNames = getThNames();
        } else {
            thNames = [];
        }

        const forestPlots = thNames.filter(th => !Boolean(th.isSimple) && isTherapyActive(th.name) && isTherapyDatasetAvailable(th.name)).map((th, id) => {
            return (
                <Box key={id}>
                    <PanelDivider/>
                    <PaperColorSelect
                        className={classes.contentBox}
                        seltype={this.getTherapyIndex(th.name) === getSelThId() ? SelectType.POSITIVE : SelectType.NONE}>
                        {forestPlotCharts(th)}
                    </PaperColorSelect>
                </Box>
            );
        });

        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                charts={undefined}
                forestPlots={forestPlots}
                // loadCharts={this.loadAllBaselines()}
                loadCharts={undefined}
                needUpdate={new Date().getTime()}
                setExpandedCharts={this.setExpandedCharts}
            >
            </ExpandChartsPanel>
        );

        this.setForestPlotView(forestPlotView);
    };

    treatPanel = () => {
        const {
            classes,
            getRecomentTextsView,
            getThNames,
            pscoreChart,
            getSelThId,
            isTherapyActive,
            isTherapyDatasetAvailable,
            setSelThId,
        } = this.props;

        // console.error('+++ treatPanel() +++ getSelThId():', getSelThId());

        let thNames;

        if (getThNames !== undefined) {
            thNames = getThNames();
        } else {
            thNames = [];
        }

        if (!thNames.length) {
            return (undefined);
        } else {
            return (
                <Paper>
                    <Typography variant={"h6"} className={classes.contentBox} align={"center"}>
                        {this.getPanelTitle()}
                    </Typography>

                    {getRecomentTextsView && getRecomentTextsView()}

                    <Box className={classes.contentBox}>
                        <Grid container spacing={2}>
                            {thNames.map((th, id) => (
                                <Grid key={id} item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      xl={6}
                                >
                                    {th.isSimple ? this.getSimpleTreatmentsView(th) : this.getTreatmentsView(th)}
                                </Grid>
                            ))}

                            {/*skip select*/}
                            <Grid item xs={12}>
                                <Grid container justifyContent='flex-end'>
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={getSelThId() === -1}
                                                    color='primary'
                                                />
                                            }
                                            onChange={() => {
                                                if (setSelThId) {
                                                    setSelThId(-1, undefined, true);
                                                }
                                            }}
                                            label={
                                                <Typography color={'primary'}>
                                                    <FormattedMessage id='label.therapy.select.skip'
                                                                      defaultMessage='Skip therapy selection'/>
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                        {thNames.filter(th => !Boolean(th.isSimple) && isTherapyActive(th.name)
                            && isTherapyDatasetAvailable(th.name)).map((th, id) => (
                            <Box key={id}>
                                <PanelDivider/>
                                <PaperColorSelect
                                    className={classes.contentBox}
                                    seltype={this.getTherapyIndex(th.name) === getSelThId() ? SelectType.POSITIVE : SelectType.NONE}>
                                    {pscoreChart(th)}
                                </PaperColorSelect>
                            </Box>
                        ))}
                        {/*{thNames.filter(th => !Boolean(th.isSimple) && isTherapyActive(th.name)*/}
                        {/*  && isTherapyDatasetAvailable(th.name)).map((th, id) => (*/}
                        {/*  <Box key={id}>*/}
                        {/*    <PanelDivider />*/}
                        {/*    <PaperColorSelect*/}
                        {/*      className={classes.contentBox}*/}
                        {/*      seltype={this.getTherapyIndex(th.name) === getSelThId() ? SelectType.POSITIVE : SelectType.NONE}>*/}
                        {/*      {forestPlotCharts(th)}*/}
                        {/*    </PaperColorSelect>*/}
                        {/*  </Box>*/}
                        {/*))}*/}
                    </Box>
                </Paper>
            );
        }
    };

    articlesPanel = () => {
        const {classes} = this.props;
        const {getSelectLine, getThNames} = this.props;
        const {sgTreatments} = getSelectLine() || {};
        let thNames = getThNames().map(th => th.name);

        return (
            <Box className={classes.contentBoxTopSpace}>
                <ArticlesPanel onChange={this.onArticlesPanelChange}>
                    <ArticlesPanelSummary
                        expandIcon={<ExpandMoreIcon/>}>
                        <Typography variant={"h6"}>
                            <FormattedMessage id={'label.articles'} defaultMessage={'Articles'}/>
                        </Typography>
                    </ArticlesPanelSummary>
                    <AccordionDetails>
                        <Box>
                            {(sgTreatments || [])
                                .filter(tr => thNames.includes(tr.name) && tr.articles !== undefined && tr.articles.length > 0)
                                .map((tr, id) => (
                                    <Box key={id}>
                                        <PanelDivider/>
                                        <SelTextView color={"primary"}>{tr.fullName}</SelTextView>
                                        <ArticlesListEx articleIds={tr.articles}/>
                                    </Box>
                                ))
                            }
                        </Box>
                    </AccordionDetails>
                </ArticlesPanel>
            </Box>
        );
    };

    updateViewContextPanel = () => {
        const {updateContextPanel} = this.props;

        if (Boolean(updateContextPanel)) {
            updateContextPanel();
        }
    };

    checkSelectTherapy = () => {
        const {getThData, isTherapyActive, setSelThId} = this.props;
        const thData = getThData();

        if (!Boolean(thData)) {
            return;
        }

        const indxs = thData.map((th, i) => {
            if (!isTherapyActive(th.name)) {
                return -1;
            }
            return i;
        }).filter(i => i !== -1);

        // console.error('+++ checkSelectTherapy() +++ indxs:', indxs);

        if (indxs.length === 1 && thData[indxs[0]].data !== undefined) {
            // console.error('+++ checkSelectTherapy() +++ thData[indxs[0]]:', thData[indxs[0]]);
            setSelThId(indxs[0]);
        }
    };

    forestPlotPanel = () => {
        const {classes} = this.props;
        const {forestPlotView} = this.state;

        return (
            <Box className={classes.contentBoxTopSpace}>
                {forestPlotView}
            </Box>
        );
    };

    render() {
        const { classes, getThNames } = this.props;

        let thNames;
        if (getThNames !== undefined) {
            thNames = getThNames();
        } else {
            thNames = [];
        }

        if (thNames.length > 0) {
            return (
                <Box>
                    {this.treatPanel()}
                    {this.forestPlotPanel()}
                    {this.articlesPanel()}
                </Box>
            );
        } else {
            return (
                <Paper className={classes.contentBoxCenter}>
                    <Typography>
                        <FormattedMessage id="label.therapy.not_available"
                            defaultMessage="Therapy is not indicated" />
                    </Typography>
                </Paper>
            );
        }
    }
}

TherapyTreats.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    touched: PropTypes.bool,
    lines: PropTypes.object.isRequired,
    getSelectLine: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    getThNames: PropTypes.func.isRequired,
    getThData: PropTypes.func.isRequired,
    setThData: PropTypes.func.isRequired,
    getSelThId: PropTypes.func.isRequired,
    setSelThId: PropTypes.func.isRequired,
    getIsSelThId: PropTypes.func.isRequired,
    getSelRefId: PropTypes.func.isRequired,
    updateContextPanel: PropTypes.func,
    getTherapyWithName: PropTypes.func.isRequired,
    isTherapyActive: PropTypes.func.isRequired,
    isTherapyRequired: PropTypes.func.isRequired,
    getTherapyActiveTexts: PropTypes.func.isRequired,
    getFeatures: PropTypes.func.isRequired,
    getTherapyCustomTreatments: PropTypes.func.isRequired,
    getTherapyFullname: PropTypes.func.isRequired,
    getTherapyData: PropTypes.func.isRequired,
    forestPlotCharts: PropTypes.func.isRequired,
    pscoreChart: PropTypes.func.isRequired,
    updateCompStat: PropTypes.func.isRequired,
    onSkipDrugSelect: PropTypes.func.isRequired,
    setSelReferentId: PropTypes.func.isRequired,
    getSelReferent: PropTypes.func.isRequired,
    getSelReferentId: PropTypes.func.isRequired,
    getSelVarIds: PropTypes.func.isRequired,
    setTherapyTreatmentForGroupedBarChart: PropTypes.func,
    setForestPlotView: PropTypes.func,
    activePatient: PropTypes.object,
};
