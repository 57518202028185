import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Typography,
    Grid,
    Breadcrumbs,
    Checkbox, Tabs
} from "@material-ui/core";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {getDrugsWithTherapyGroups} from "../../query/HPBaseline";
import {FormattedMessage, injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import {getHPDrugsChart} from '../../query/HPBaseline';
import {getQueryResult, getServiceMessageDrug, getServiceMessages, newAnyDataRef} from '../../utils';
import {DrugAvailStatus, DrugCompStatus, MessageType} from '../../const';
import config from "../../config";
import ServiceRadioGroup from "../button/ServiceRadioGroup";
import type {ServiceMessage} from "../../const";
import {InfoHelp} from "../common/InfoHelp";
import {
    setLoadChartsMethod,
    updateExpanded,
    updateShowPanel,
    updateStartLoadCharts
} from "../../service/expand-charts-panel-service";

const styles = theme => ({
    ...mainStyles(theme),
});

class HPSelectDrugs extends React.Component {

    abortController = new AbortController();
    abortChartController = new AbortController();

    constructor(props) {
        super(props);

        this.state = {
            selGroupId: 0,
            curChart: {},
        };
    }

    componentDidMount() {
        this.forestPlotView();
        this.loadDrugs();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.abortChartController.abort();
    }

    loadDrugs = () => {
        const {
            client, setTherapyGroupDrugs, getSelectTherapyComb, getSelectTherapyType,
            setCombDrugs, getTouched, activePatient
        } = this.props;
        const {signal} = this.abortController;
        const selThComb = getSelectTherapyComb();
        const selThType = getSelectTherapyType();

        if (Boolean(selThComb) && Boolean(selThType) && getTouched()) {
            client.query({
                query: getDrugsWithTherapyGroups,
                variables: {
                    patientId: Number(activePatient.patient) || 0,
                    thGIds: selThComb.combination.map(it => it.id),
                    thTypeId: selThType.id,
                },
                context: {
                    uri: config.options.server.hp_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    }
                }
            })
                // .then(result => (result.data || {}).getDrugsWithTherapyGroups)
                // .then(result => JSON.parse(JSON.stringify(result)))
                .then(result => getQueryResult(result?.data, 'getDrugsWithTherapyGroups'))
                .then(thGroupDrugs => {
                    // TODO:  всё сохранить в глобальном, но локально ничего не храним, поэтому этого достаточно!!!
                    setTherapyGroupDrugs((thGroupDrugs || {}).groupDrugs, () => {
                        // this.loadChart();
                    });
                    setCombDrugs((thGroupDrugs || {}).combDrugs);
                });

        } else {
            // this.loadChart();
        }
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    loadChart = (resetRefId = false, updateForestPlotView = false) => (callback) => {
        const {getTherapyGroupDrugs, client} = this.props;
        const {selGroupId, selRefId} = this.state;
        const thGroupDrugs = getTherapyGroupDrugs();

        // console.error('+++ HPSelectDrugs +++ loadChart() +++ expandedCharts:', expandedCharts);
        // console.error('+++ HPSelectDrugs +++ loadChart() +++ thGroupDrugs:', thGroupDrugs);
        // console.error('+++ HPSelectDrugs +++ loadChart() +++ thGroupDrugs.items.length:', thGroupDrugs.items.length);
        // console.error('+++ HPSelectDrugs +++ loadChart() +++ selGroupId:', selGroupId);
        // if (expandedCharts && Boolean(thGroupDrugs) && thGroupDrugs.items.length > selGroupId) {
        if (!!thGroupDrugs && (thGroupDrugs.items || []).length > selGroupId) {
            const selGroup = thGroupDrugs.items[selGroupId];
            const {signal} = this.abortChartController;
            const _selGroupId = newAnyDataRef(selGroup.id);

            return client.query({
                query: getHPDrugsChart,
                variables: {
                    groupIds: [_selGroupId],
                    refId: resetRefId ? undefined : selRefId,
                },
                context: {
                    uri: config.options.server.hp_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                },
            })
                .then(result => getQueryResult(result?.data, 'getHPDrugsChart'))
                .then(chart => {
                    if (!!chart) {
                        const _selRefId = selRefId && !resetRefId ? selRefId : chart.drugs[0].id;
                        this.setState(prevState => ({
                            // curChart: chart,
                            curChart: {
                                ...prevState.curChart,
                                [selGroupId]: chart,
                            },
                            selRefId: _selRefId,
                        }), () => {
                            // TODO: +++2021.12.03+++
                            if (updateForestPlotView) {
                                // TODO: +++2021.12.03+++
                                // this.forestPlotView();
                            }
                            if (!!callback) {
                                callback();
                            }
                        });
                    } else {
                        this.setState(prevState => ({
                            // curChart: undefined,
                            curChart: {
                                ...prevState.curChart,
                                [selGroupId]: undefined,
                            },
                            selRefId: undefined,
                        }), () => {
                            // TODO: +++2021.12.03+++
                            if (updateForestPlotView) {
                                // TODO: +++2021.12.03+++
                                // this.forestPlotView();
                            }
                            if (!!callback) {
                                callback();
                            }
                        });
                    }
                    return new Promise((resolve, reject) => {
                        resolve([chart]);
                    });
                });
        } else {
            if (updateForestPlotView) {
                // TODO: +++2021.12.03+++
                // this.forestPlotView();
            }
            return new Promise((resolve, reject) => {
                resolve(null);
            });
        }
    };

    onTabChange = (e, newValue) => {
        updateStartLoadCharts(false);
        updateExpanded(false);

        // console.error('+++ HPSelectDrugs +++ onTabChange() +++ selGroupId:', selGroupId);
        // console.error('+++ HPSelectDrugs +++ onTabChange() +++ curChart:', curChart);
        // console.error('+++ HPSelectDrugs +++ onTabChange() +++ newValue:', newValue);
        this.setState({
            selGroupId: newValue,
        }, () => {
            // TODO: +++2021.12.03+++
            //  this.loadChart(true, true)();
            // this.forestPlotView(); // TODO: +++2021.12.03+++ added!!!
        });
    };

    onDrugChange = (groupId) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const {setDrugForTherapyGroup} = this.props;
        setDrugForTherapyGroup(groupId, Number(e.target.value));
    };

    drugsView = () => {
        const {classes, getTherapyGroupDrugs, intl, isPrescriptionReadonly} = this.props;
        const {selGroupId} = this.state;
        const thGroupDrugs = getTherapyGroupDrugs();

        if (!Boolean(thGroupDrugs) || selGroupId === undefined || selGroupId >= thGroupDrugs.items) {
            return (<Box/>);
        }

        const selGroup = thGroupDrugs.items[selGroupId];
        if (!Boolean(selGroup) || !Boolean(selGroup.takeGroups)) {
            return (<Box/>);
        }

        const naStr = intl.formatMessage({id: 'message.data.empty.short', defaultMessage: 'NA'});

        return (
            <Paper className={classes.contentBox + (isPrescriptionReadonly ? (' ' + classes.disabled) : '')}
                   variant={"outlined"}>
                <SelTextView>
                    <FormattedMessage id='label.drug.select' defaultMessage='Select drug:'/>
                </SelTextView>
                <PanelDivider/>
                <Grid container spacing={2}>
                    {selGroup.takeGroups.sort((g1, g2) => g1.id - g2.id).map(tg => (
                        <Grid key={tg.id} item xs={12} sm={12}>
                            {tg.name && <SelTextView>{tg.name}</SelTextView>}
                            <FormControl component='fieldset'>
                                <ServiceRadioGroup
                                    onChange={this.onDrugChange(selGroup.id)}
                                    value={selGroup.selDrugId ? selGroup.selDrugId : 0}
                                    getServiceMessage={getServiceMessageDrug(tg.drugs)}
                                    showService={false}
                                >
                                    {tg.drugs
                                        .map((drug, i) => {
                                                const isDrugNotAvailable = (drug.availStatus !== undefined && drug.availStatus === DrugAvailStatus.NOT_ON_SALE);
                                                let drugStyle;
                                                const isMatch = Boolean(drug.isMatch);
                                                const compStatus = Boolean(drug.compStatus) ? drug.compStatus : 0;
                                                const drugNaStr = ''; // drug.dataAvailable ? '' : ' - '.concat(naStr);

                                                if (isDrugNotAvailable) {
                                                    drugStyle = classes.drugNotAvailable;
                                                } else if (compStatus !== DrugCompStatus.NORMAL) {
                                                    switch (compStatus) {
                                                        case DrugCompStatus.WARNING:
                                                            drugStyle = classes.drugWarning;
                                                            break;
                                                        case DrugCompStatus.DANGER:
                                                            drugStyle = classes.drugDanger;
                                                            break;
                                                        default:
                                                            drugStyle = classes.drugNormal;
                                                    }
                                                } else if (isMatch) {
                                                    drugStyle = classes.drugRecommend;
                                                }

                                                let serviceInfoHelp: ServiceMessage = getServiceMessages(drug.messages, MessageType.INFO);

                                                return (
                                                    <Grid container direction={"row"} key={i}>
                                                        <Grid item>
                                                            <FormControlLabel
                                                                key={drug.id}
                                                                value={drug.id}
                                                                label={<Typography className={drugStyle}>
                                                                    {`${drug.name}${drugNaStr}`}
                                                                </Typography>}
                                                                // disabled={isDrugNotAvailable || compStatus === DrugStatus.DANGER}
                                                                disabled={isDrugNotAvailable}
                                                                control={<Radio color={'primary'}/>}
                                                            />
                                                        </Grid>
                                                        {serviceInfoHelp?.showService &&
                                                        <Grid item>
                                                            <InfoHelp texts={serviceInfoHelp.messages}/>
                                                        </Grid>
                                                        }
                                                    </Grid>
                                                );
                                            }
                                        )}
                                </ServiceRadioGroup>
                            </FormControl>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selGroup.selDrugId === -1}
                                            color='primary'
                                        />
                                    }
                                    onChange={this.onSkipDrugSelect(selGroup.id)}
                                    label={
                                        <Typography color={selGroup.selDrugId === -1 ? 'primary' : 'textPrimary'}>
                                            <FormattedMessage id='label.drug.select.skip'
                                                              defaultMessage='Skip drug selection'/>
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Typography className={classes.drugNotAvailable} variant='caption'>
                                <FormattedMessage id="label.drug.not_available"
                                                  defaultMessage="Not pay available in Russia"/>
                            </Typography>
                        </div>
                        <div>
                            <Typography className={classes.drugRecommend} variant='caption'>
                                <FormattedMessage id="label.drug.recommend" defaultMessage="Drug recommend"/>
                            </Typography>
                        </div>
                        <div>
                            <Typography className={classes.drugWarning} variant='caption'>
                                <FormattedMessage id="label.drug.warning" defaultMessage="Drug warning"/>
                            </Typography>
                        </div>
                        <div>
                            <Typography className={classes.drugDanger} variant='caption'>
                                <FormattedMessage id="label.drug.danger" defaultMessage="Drug danger"/>
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        );
    };

    onSkipDrugSelect = (groupId) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        if (e.target.checked) {
            const {setDrugForTherapyGroup} = this.props;
            setDrugForTherapyGroup(groupId, -1);
        }
    };

    onReferentChange = (e) => {
        updateExpanded(false);
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        this.setState({
            selRefId: Number(e.target.value),
        }, () => {
            // TODO: +++2021.12.03+++
            //  this.loadChart();
            // this.forestPlotView(); // TODO: +++2021.12.03+++ added!!!
        });
    };

    referentsView = () => {
        const {classes, isPrescriptionReadonly} = this.props;
        const {curChart, selRefId, selGroupId} = this.state;

        // if (Boolean(curChart)) {
        if (Boolean(curChart[selGroupId])) {
            return (
                <Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        <SelTextView>
                            <FormattedMessage id='label.hp.referent' defaultMessage='Referent'/>
                        </SelTextView>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={selRefId}
                                onChange={this.onReferentChange}
                            >
                                {curChart[selGroupId].drugs.map(item => (
                                    <FormControlLabel
                                        key={item.id}
                                        value={item.id}
                                        label={item.name}
                                        control={<Radio color={'primary'}/>}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    drugCombinationsView = () => {
        const {classes, getTherapyGroupDrugs, getCombDrugs, isPrescriptionReadonly} = this.props;
        const combDrugs = getCombDrugs();

        if (combDrugs && combDrugs.length > 0) {
            const thGroupDrugs = getTherapyGroupDrugs();
            const selDrugIds = thGroupDrugs.items
                .filter(it => it.selDrugId)
                .map(it => it.selDrugId);

            const drugIds = thGroupDrugs.items
                .filter(it => it.selDrugId)
                .flatMap(it => it.takeGroups)
                .flatMap(it => it.drugs)
                .filter(it => selDrugIds.includes(it.id))
                .map(it => it.drugId);

            return (
                <Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        <SelTextView>
                            <FormattedMessage id='label.hp.drugs.combinations' defaultMessage='Existing combinations'/>
                        </SelTextView>
                        <ul>
                            {combDrugs.map(combDrug => (
                                <li key={combDrug.combId}>
                                    <Breadcrumbs>
                                        {combDrug.drugs.map(drug =>
                                            <Typography
                                                color={drugIds.includes(drug.id) ? "primary" : "textSecondary"}
                                                key={drug.id}>
                                                {drug.name}
                                            </Typography>
                                        )}
                                    </Breadcrumbs>
                                </li>
                            ))}
                        </ul>
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    statisticsView = () => {
        const {classes} = this.props;
        // const thGroup = this.getSelectGroup();
        // let selection;
        /*
        if (Boolean(thGroup) && thGroup.n && thGroup.n > 0) {
          selection = `Selection: N=${thGroup.n}(number of studies see in forest-plots)`;
        } else {
          selection = 'Selection: Not enough data';
        }
        */
        return (
            <Box>
                <PanelDivider/>
                <Paper className={classes.contentBox} variant={"outlined"}>
                    <Typography>
                        <FormattedMessage id="label.bl.network.meta"
                                          defaultMessage="Network Meta-analysis: The R Project for Statistical Computing"/>
                    </Typography>
                    <Typography>
                        <FormattedMessage id="label.bl.origin.articles"
                                          defaultMessage="Origin(see Articles): Cochrane Database, Medline/PubMed Database"/>
                    </Typography>
                </Paper>
            </Box>
        );
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadChart(false, false));

/*
        console.error('+++ HPSelectDrugs +++ forestPlotView() +++ selGroupId:', selGroupId);
        console.error('+++ HPSelectDrugs +++ forestPlotView() +++ curChart:', curChart);
        console.error('+++ HPSelectDrugs +++ forestPlotView() +++ curChart[selGroupId]:', curChart[selGroupId]);

        const forestPlotView = (
            <ExpandChartsPanel
                textSummary={intl.formatMessage({
                    id: 'label.charts.forestplots',
                    defaultMessage: 'Графики'
                })}
                // charts={[curChart]}
                charts={[curChart[selGroupId]]}
                loadCharts={this.loadChart(false, false)}
                needUpdate={new Date().getTime()}
                setExpandedCharts={this.setExpandedCharts}
            >
            </ExpandChartsPanel>
        );

        setForestPlotView(forestPlotView, () => {
            console.error('+++ HPSelectDrugs +++ forestPlotView() +++ setForestPlotView(forestPlotView, () => {: +++');
        });
*/
    };

    render() {
        const {classes, getRecomentTextsView, getTherapyGroupDrugs} = this.props;
        const {selGroupId} = this.state;
        const thGroupDrugs = getTherapyGroupDrugs();

        return (
            <Box>
                {getRecomentTextsView && getRecomentTextsView()}
                {thGroupDrugs &&
                <Box className={classes.contentBox}>
                    <Tabs
                        classes={{ root: classes.root, scroller: classes.scroller }}
                        indicatorColor={"primary"}
                        textColor={"primary"}
                        value={selGroupId}
                        onChange={this.onTabChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        // centered
                    >
                        {thGroupDrugs.items.map(group => (
                            <Tab key={group.id} label={group.shortName}/>
                        ))}
                    </Tabs>
                    <PanelDivider/>
                    {this.drugsView()}
                    {this.drugCombinationsView()}
                    {this.statisticsView()}
                    {/* {this.referentsView()} */}
                    {/*{this.forestPlotView()}*/}
                </Box>
                }
            </Box>
        );
    }
}

HPSelectDrugs.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    getTouched: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    getTherapyGroupDrugs: PropTypes.func.isRequired,
    setTherapyGroupDrugs: PropTypes.func.isRequired,
    setDrugForTherapyGroup: PropTypes.func.isRequired,
    getSelectTherapyComb: PropTypes.func.isRequired,
    getSelectTherapyType: PropTypes.func.isRequired,
    setCombDrugs: PropTypes.func.isRequired,
    getCombDrugs: PropTypes.func.isRequired,
    setForestPlotView: PropTypes.func.isRequired,
    getSelectVars: PropTypes.func.isRequired,
    activePatient: PropTypes.object.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(HPSelectDrugs);
