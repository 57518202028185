import React from "react";
import PropTypes from 'prop-types';
import {
    Box,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
    Grid,
    Checkbox, Link
} from "@material-ui/core";
import SelTextView from "../SelTextView";
import PanelDivider from "../PanelDivider";
import {FormattedMessage, injectIntl} from "react-intl";
import mainStyles from "../../styles/mainStyles";
import compose from "recompose/compose";
import {withStyles} from "@material-ui/core/styles";
import {withApollo} from '@apollo/client/react/hoc';
import config from "../../config";
import {getChartA, getKeyValue} from "../../query/HeartFailureBaseline";
import {
    filterDrugsByRegimens, getDrugStyleObject,
    getQueryResult,
    getServiceMessageDrug,
    getServiceMessages, getServiceMessagesDangerWarning, isMultiselect, newAnyDataRef,
    sortByOrder
} from "../../utils";
import {ChartsInputDto, ChartType, DrugAvailStatus, DrugCompStatus, MessageType} from "../../const";
import type {ServiceMessage} from "../../const";
import {InfoHelp} from "../common/InfoHelp";
import ServiceRadioGroup from "../button/ServiceRadioGroup";
import {HelpDrugsTextsView} from "../common/HelpDrugsTextsView";
import ServiceCheckbox from "../button/ServiceCheckbox";
import SelectDrugRegimenDialog from "../drug/SelectDrugRegimenDialog";
import {
    setLoadChartsMethod,
    updateExpanded,
    updateShowPanel,
    updateStartLoadCharts
} from "../../service/expand-charts-panel-service";
import {loadGetCharts} from "../../query/common";

const styles = theme => ({
    ...mainStyles(theme),
    drugLabel: {
        display: 'flex',
        flexDirection: 'row',
    },
    drugRegimens: {
        marginLeft: '4px',
    },
});

class DyslipidemiaSelectDrugs extends React.Component {

    abortController = new AbortController();
    abortChartController = new AbortController();

    steps;
    selGroup;
    drugGroups = [];

    constructor(props) {
        super(props);

        this.state = {
            selGroupId: 0,
            charts: {},
            statistics: [],
            openSelectDrugRegimenDialog: false,
            selectDrugRegimenDialog: undefined,
            selectGroupDrugRegimenDialog: undefined,
        };
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
        if (nextProps.activeStep !== this.props.activeStep) {
            this.drugGroups = [];
            this.selGroup = undefined;
            this.setState({
                selGroupId: 0,
                charts: {},
                statistics: [],
                openSelectDrugRegimenDialog: false,
                selectDrugRegimenDialog: undefined,
                selectGroupDrugRegimenDialog: undefined,
            }, () => {
                // this.forceUpdate();
            });
        }
    }
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.activeStep !== this.props.activeStep) {
            this.componentDidMount();
        }
    }

    componentDidMount() {
        // this.loadDrugs();
        this.setVars();
    }

    componentWillUnmount() {
        this.abortController.abort();
        this.abortChartController.abort();
    }

    setVars = () => {
        const {
            getTherapyGroupDrugs,
        } = this.props;
        const {selGroupId} = this.state;

        this.drugGroups = getTherapyGroupDrugs();
        this.selGroup = (this.drugGroups || {})[selGroupId];

        this.setSelected();
        // this.loadStatistics();
        this.forestPlotView();
    }

    setSelected = () => {
        const {
            nosologyData,
            updateContextPanel,
            activeStep,
        } = this.props;

        if (
            !nosologyData.touched
        ) {
            let selDrugs = {};

            (this.drugGroups || []).forEach((drugGroup) => {
                // console.error('+++ drugGroup:', drugGroup);
                drugGroup.drugs.forEach((drug) => {
                    if (drug.selected) {
                        drug.groupName = drugGroup.name;
                        drug.groupShortName = drugGroup.shortName;

                        drug.selectedRegimen = drug.regimens.filter((regimen) => regimen.selected)[0];

                        if (drug.id === -1) {
                            selDrugs[drugGroup.id] = [];
                        }

                        selDrugs[drugGroup.id] = selDrugs[drugGroup.id] ? (isMultiselect(drugGroup) ? [...selDrugs[drugGroup.id], ...[drug]] : [drug]) : [drug];
                    }
                });
            });

            if (!nosologyData.selDrugs) {
                nosologyData.selDrugs = {};
            }
            nosologyData.selDrugs[activeStep] = selDrugs;

            this.setState(prevState => ({
                nosologyData: {
                    ...prevState.nosologyData,
                    selDrugs: nosologyData.selDrugs,
                },
            }), () => {
                updateContextPanel();
            });
        }
    }

    loadStatistics = () => {
        const {client, setStatistics, getStatistics, patientId} = this.props;
        const {signal} = this.abortController;
        const statistics = getStatistics();

        if (!statistics || statistics.length === 0) {
            client.query({
                query: getKeyValue,
                variables: {
                    patientId: patientId,
                },
                context: {
                    uri: config.options.server.heart_failure_url + config.options.server.api_uri,
                    fetchOptions: {
                        signal,
                    },
                }
            })
                // .then(result => (result.data || {}).getKeyValue)
                // .then(result => JSON.parse(JSON.stringify(result)))
                .then(result => getQueryResult(result?.data, 'getKeyValue'))
                .then(statistics => {
                    setStatistics(statistics, () => {
                        this.setState({
                            statistics: statistics,
                        });
                    });
                });
        } else {
            this.setState({
                statistics: statistics,
            });
        }
    };

    loadChart = (callback) => {
        // console.error('+++ loadChart() +++');
        const {client, nosologyId, patientId} = this.props;
        const {charts} = this.state;

        // if (expandedCharts && !Boolean(charts[(this.selGroup || {}).id])) {
        if (!Boolean(charts[(this.selGroup || {}).id])) {
            const {signal} = this.abortChartController;
            const selGroupId = newAnyDataRef((this.selGroup || {}).id);
            const chartsInputDto: ChartsInputDto = {
                ids: [selGroupId],
                nosologyId: Number(nosologyId),
                patientId: Number(patientId),
                type: ChartType.FOREST_GROUP,
            };
            // console.error('+++ loadChart() +++ chartsAInputDto:', chartsAInputDto);
            return loadGetCharts(client, signal, chartsInputDto)
                .then((chartsResponse) => {
                    // console.error('+++ loadChart() +++ chartsResponse:', chartsResponse);
                    // console.error('+++ loadChart() +++ selGroupId:', selGroupId);
                    if (!!chartsResponse) {
                        // charts[(this.selGroup || {}).id] = chartsResponse;
                        charts[chartsResponse.id] = chartsResponse.charts;

                        this.setState({
                            charts: charts,
                        }, () => {
                            if (!!callback) {
                                callback();
                            } else {
                            }
                        });
                        return new Promise((resolve, reject) => {
                            resolve(chartsResponse.charts);
                        });
                    } else {
                        return new Promise((resolve, reject) => {
                            resolve(null);
                        });
                    }
                });
        } else {
            if (!!callback) {
                callback();
            }
            return new Promise((resolve, reject) => {
                resolve(charts[(this.selGroup || {}).id]);
            });
        }
    };

    onTabChange = (e, newValue) => {
        // this.abortChartController.abort();
        updateStartLoadCharts(false);
        // updateExpanded(false);
        // console.error('+++ onTabChange() +++ newValue:', newValue);

        this.selGroup = (this.drugGroups || []).filter((drugGroup) => drugGroup.drugs && drugGroup.drugs.length > 0)[newValue];
        // console.error('+++ onTabChange() +++ this.selGroup:', this.selGroup);
        this.setState({
            selGroupId: newValue,
        }, () => {
            updateExpanded(false);
        });
    };

    onDrugChange = (group) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        const drugId = Number(e.target.value);
        this.setSelectDrug(group, drugId);
    };

    onDrugChangeSkip = (group) => (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        this.setSelectDrug(group, -1);
    };

    setSelectDrug = (group, drugId) => {
        const {
            setDrugForTherapyGroup,
            activeStep,
        } = this.props;
        const {nosologyData} = this.state;

        // console.error('+++ setSelectDrug() +++ this.selGroup:', this.selGroup);
        // console.error('+++ setSelectDrug() +++ group:', group);
        // console.error('+++ setSelectDrug() +++ drugId:', drugId);

        let drug = {};
        if (drugId !== -1) {
            drug = ((this.selGroup || {}).drugs || []).filter((drug) => '' + drug.id === '' + drugId)[0];
        } else {
            drug.id = drugId;
            drug.groupName = group.name;
            drug.groupShortName = group.shortName;
        }
        // console.error('+++ setSelectDrug() +++ drug:', drug);

        const selectedDrug = (((nosologyData.selDrugs || {})[activeStep] || {})[(this.selGroup || {}).id] || []).find((selectedDrugDrug) => '' + selectedDrugDrug.id === '' + drug.id);

        if (drugId !== -1 && isMultiselect(group) && nosologyData.selDrugs && nosologyData.selDrugs[activeStep] && nosologyData.selDrugs[activeStep][(this.selGroup || {}).id]) {
            const selDrugsLength = nosologyData.selDrugs[activeStep][(this.selGroup || {}).id].length;
            // console.error('+++ nosologyData.selDrugs[(this.selGroup || {}).id]:', nosologyData.selDrugs[(this.selGroup || {}).id]);
            // console.error('+++ selDrugsLength:', selDrugsLength);
            // FIXME: +++2023.02.04+++ для тестирования, убрать true
            if (selDrugsLength >= group.multiselectMax && !selectedDrug) {
                return;
            }
        }

        if (!!drug.regimens && !!drug.regimens.length) {
            if (nosologyData.selDrugs && nosologyData.selDrugs[activeStep] && nosologyData.selDrugs[(this.selGroup || {}).id]) {
                if (isMultiselect(group) && !!selectedDrug) {
                    setDrugForTherapyGroup(group, drug);
                } else {
                    this.openSelectDrugRegimenDialog(drug, group);
                }
            } else {
                this.openSelectDrugRegimenDialog(drug, group);
            }
        } else {
            setDrugForTherapyGroup(group, drug);
        }
    }

    openSelectDrugRegimenDialog = (drug, group) => {
        this.setOpenSelectDrugRegimenDialog(true, drug, group);
    }

    beforeOpenSelectDrugRegimenDialog = (drug, group) => {
        const {activeStep} = this.props;
        const {nosologyData} = this.state;
        const selectedDrug = (((nosologyData.selDrugs || {})[activeStep] || {})[(this.selGroup || {}).id] || []).find((selectedDrugDrug) => '' + selectedDrugDrug.id === '' + drug.id);
        if (!!selectedDrug) {
            this.openSelectDrugRegimenDialog(drug, group);
        } else {
            this.setSelectDrug(group, drug.id);
        }
    }

    isCheckedDrug = (drug): boolean => {
        const {activeStep} = this.props;
        const {nosologyData} = this.state;
        if (nosologyData.selDrugs && nosologyData.selDrugs[activeStep] && nosologyData.selDrugs[(this.selGroup|| {}).id]) {
            return nosologyData.selDrugs[activeStep][(this.selGroup || {}).id].some((selectedDrug) => '' + selectedDrug.id === '' + drug.id);
        }
        return false;
    }

    multiSelectDrugs = () => {
        const {
            intl,
            classes,
            activeStep,
        } = this.props;
        const {nosologyData} = this.state;

        const naStr = intl.formatMessage({id: 'message.data.empty.short', defaultMessage: 'NA'});
        const selectedDrugs = nosologyData.selDrugs && nosologyData.selDrugs[activeStep] && nosologyData.selDrugs[(this.selGroup || {}).id] ? nosologyData.selDrugs[activeStep][(this.selGroup || {}).id] : undefined;

        return (
            <FormControl component='fieldset'>
                {((this.selGroup || {}).drugs || [])
                    .filter(filterDrugsByRegimens)
                    .sort(sortByOrder)
                    .map((drug) => {
                        const drugStyleObject = getDrugStyleObject(drug, classes);
                        const serviceInfoHelp: ServiceMessage = getServiceMessages(drug.messages, MessageType.INFO);
                        const serviceWarningCheckBox: ServiceMessage = getServiceMessagesDangerWarning(drug.messages);
                        let selectedDrug;
                        if (!!selectedDrugs) {
                            selectedDrug = selectedDrugs.find((selectedDrugsDrug) => '' + selectedDrugsDrug.id === '' + drug.id);
                        }
                        const drugName = drug.name; // + (drug.dataAvailable ? '' : ' - '.concat(naStr));
                        const regimenName = !!selectedDrug && '' + selectedDrug.id === '' + drug.id && !!selectedDrug.selectedRegimen ? selectedDrug.selectedRegimen.name : '';

                            return (
                                <Grid container direction={"row"} key={drug.id}>
                                    <Grid item>
                                        <FormControlLabel
                                            value={drug.id}
                                            disabled={drugStyleObject.isDrugNotAvailable}
                                            label={<Box className={classes.drugLabel}>
                                                <Typography className={drugStyleObject.drugStyle}>
                                                    {/*{`${drug.name}${drug.dataAvailable ? '' : ' - '.concat(naStr)}`}*/}
                                                    {drugName}
                                                </Typography>
                                                {!!regimenName &&
                                                    <Box className={classes.drugRegimens}> - <Link onClick={() => this.beforeOpenSelectDrugRegimenDialog(drug, this.selGroup)}>{!!regimenName ? regimenName : 'Выбрать дозировку'}</Link></Box>
                                                }
                                            </Box>}
                                            control={
                                                <ServiceCheckbox
                                                    color={"primary"}
                                                    checked={this.isCheckedDrug(drug)}
                                                    value={drug.id}
                                                    onChange={this.onDrugChange(this.selGroup)}
                                                    showService={serviceWarningCheckBox.showService}
                                                    text={serviceWarningCheckBox.messages}
                                                    type={serviceWarningCheckBox.type}
                                                />
                                            }
                                        />
                                    </Grid>
                                    {serviceInfoHelp.showService &&
                                        <Grid item>
                                            <InfoHelp texts={serviceInfoHelp.messages}/>
                                        </Grid>
                                    }
                                </Grid>
                            );
                        }
                    )
                }
            </FormControl>
        );
    }

    singleSelectDrugs = () => {
        const {
            intl,
            classes,
            activeStep,
        } = this.props;
        const {nosologyData} = this.state;

        const naStr = intl.formatMessage({id: 'message.data.empty.short', defaultMessage: 'NA'});

        const selectedDrug = nosologyData.selDrugs && nosologyData.selDrugs[activeStep] && nosologyData.selDrugs[activeStep][(this.selGroup || {}).id] ? nosologyData.selDrugs[activeStep][(this.selGroup || {}).id][0] : undefined;

        return (
            <FormControl component='fieldset'>
                <ServiceRadioGroup
                    onChange={this.onDrugChange(this.selGroup)}
                    value={selectedDrug ? selectedDrug.id : 0}
                    getServiceMessage={getServiceMessageDrug((this.selGroup || {}).drugs || [])}
                    showService={false}
                >
                    {((this.selGroup || {}).drugs || [])
                        .filter(filterDrugsByRegimens)
                        .sort(sortByOrder)
                        .map((drug, i) => {
                            const drugStyleObject = getDrugStyleObject(drug, classes);

                            let serviceInfoHelp: ServiceMessage = getServiceMessages(drug.messages, MessageType.INFO);
                            const drugName = drug.name; // + (drug.dataAvailable ? '' : ' - '.concat(naStr));
                            const regimenName = !!selectedDrug && '' + selectedDrug.id === '' + drug.id && !!selectedDrug.selectedRegimen ? selectedDrug.selectedRegimen.name : '';

                            return (
                                <Grid container direction={"row"} key={i}>
                                    <Grid item>
                                        <FormControlLabel
                                            key={drug.id}
                                            value={drug.id}
                                            // disabled={this.selGroup.isBlocked || drug.available}
                                            disabled={drugStyleObject.isDrugNotAvailable}
                                            label={<Box className={classes.drugLabel}>
                                                <Typography
                                                    className={drugStyleObject.drugStyle}
                                                >
                                                {/*{`${drug.name}${drug.dataAvailable ? '' : ' - '.concat(naStr)}`}*/}
                                                    {drugName}
                                                </Typography>
                                                {!!regimenName &&
                                                    <Box className={classes.drugRegimens}> - <Link onClick={() => this.beforeOpenSelectDrugRegimenDialog(drug, this.selGroup)}>{!!regimenName ? regimenName : 'Выбрать дозировку'}</Link></Box>
                                                }
                                            </Box>}
                                            control={<Radio color={'primary'}/>}
                                        />
                                    </Grid>
                                    {serviceInfoHelp?.showService &&
                                        <Grid item>
                                            <InfoHelp texts={serviceInfoHelp.messages}/>
                                        </Grid>
                                    }
                                </Grid>
                            )
                        })}
                </ServiceRadioGroup>
            </FormControl>
        );
    }

    setOpenSelectDrugRegimenDialog = (open: boolean, drug, group) => {
        this.setState({
            openSelectDrugRegimenDialog: open,
            selectDrugRegimenDialog: drug,
            selectGroupDrugRegimenDialog: group,
        });
    }

    onSelectDrugRegimen = (drug, regimen) => {
        const {setDrugForTherapyGroup} = this.props;
        const {selectGroupDrugRegimenDialog} = this.state;
        // console.error('+++ onSelectDrugRegimen() +++ selectGroupDrugRegimenDialog:', selectGroupDrugRegimenDialog);
        // console.error('+++ onSelectDrugRegimen() +++ drug:', drug);
        // console.error('+++ onSelectDrugRegimen() +++ regimen:', regimen);

        if (!!regimen) {
            setDrugForTherapyGroup(selectGroupDrugRegimenDialog, drug, regimen);
        }

        this.setOpenSelectDrugRegimenDialog(false);
    }

    drugsView = () => {
        const {
            classes,
            isPrescriptionReadonly,
            activeStep,
        } = this.props;
        const {nosologyData} = this.state;

        const {
            openSelectDrugRegimenDialog,
            selectDrugRegimenDialog,
        } = this.state;

        return (
            <Paper className={classes.contentBox + (isPrescriptionReadonly ? (' ' + classes.disabled) : '')}
                   variant={"outlined"}>
                <SelTextView>
                    <FormattedMessage id='label.drug.select' defaultMessage='Select drug:'/>
                </SelTextView>

                <PanelDivider/>
                <Grid container>
                    <Grid item xs={12}>
                        {isMultiselect(this.selGroup || {}) ? this.multiSelectDrugs() : this.singleSelectDrugs()}
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent='flex-end'>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!(((nosologyData.selDrugs || {})[activeStep] || {})[(this.selGroup || {}).id] || []).length ? (((nosologyData.selDrugs || {})[activeStep] || {})[(this.selGroup || {}).id])[0].id === -1 : false}
                                            color='primary'
                                        />
                                    }
                                    onChange={this.onDrugChangeSkip(this.selGroup)}
                                    label={
                                        <Typography
                                            color={!!(((nosologyData.selDrugs || {})[activeStep] || {})[(this.selGroup || {}).id] || []).length && (((nosologyData.selDrugs || {})[activeStep] || {})[(this.selGroup || {}).id])[0].id === -1 ? 'primary' : 'textPrimary'}>
                                            <FormattedMessage id='label.drug.select.skip'
                                                              defaultMessage='Skip drug selection'/>
                                        </Typography>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {openSelectDrugRegimenDialog &&
                    <SelectDrugRegimenDialog
                        onDialogClose={() => this.setOpenSelectDrugRegimenDialog(false)}
                        onSubmit={this.onSelectDrugRegimen}
                        // addedDrugsData={addedDrugsData || []}
                        isShow={openSelectDrugRegimenDialog}
                        drug={selectDrugRegimenDialog}
                        title={selectDrugRegimenDialog.name}
                        // client={client}
                        // classes={classes}
                    />
                }
            </Paper>
        );
    };

    onReferentChange = (e) => {
        const {isPrescriptionReadonly} = this.props;
        if (isPrescriptionReadonly) {
            e.preventDefault();
            return;
        }

        this.setState({
            selRefId: Number(e.target.value),
        }, () => {
            // TODO: +++2021.12.03+++
            //  this.loadChart();
            updateExpanded(false);
        });
    };

    referentsView = () => {
        const {classes, isPrescriptionReadonly} = this.props;
        const {curChart, selRefId} = this.state;

        if (Boolean(curChart)) {
            return (
                <Box className={isPrescriptionReadonly ? classes.disabled : ''}>
                    <PanelDivider/>
                    <Paper className={classes.contentBox} variant={"outlined"}>
                        <SelTextView>
                            <FormattedMessage id='label.hp.referent' defaultMessage='Referent'/>
                        </SelTextView>
                        <FormControl component='fieldset'>
                            <RadioGroup
                                value={selRefId}
                                onChange={this.onReferentChange}
                            >
                                {curChart.drugs.map(item => (
                                    <FormControlLabel
                                        key={item.id}
                                        value={item.id}
                                        label={item.name}
                                        control={<Radio color={'primary'}/>}
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    </Paper>
                </Box>
            );
        } else {
            return (<Box/>);
        }
    };

    statisticsView = () => {
        const {classes} = this.props;
        const {statistics} = this.state;
        const statisticsT: [] = (statistics || []).filter(statistic => statistic.groupId === (this.selGroup || {}).id);

        return (
            statisticsT.length > 0 &&
            <Box>
                <PanelDivider/>
                <Paper className={classes.contentBox} variant={"outlined"}>
                    {
                        statisticsT.map(statistic => (
                            <Typography key={statistic.id}>
                                {statistic.key} {statistic.value}
                            </Typography>
                        ))
                    }
                </Paper>
            </Box>
        );
    };

    setExpandedCharts = (expandedCharts, callback) => {
        this.setState(prevState => ({
            expandedCharts: expandedCharts,
        }), () => {
            if (Boolean(callback)) {
                callback();
            }
        });
    };

    forestPlotView = () => {
        updateShowPanel(true);
        setLoadChartsMethod(this.loadChart);
    };

    getNoTherapyMessage = (): ServiceMessage => {
        const {
            nosologyData,
            activeStep,
        } = this.props;
        return getServiceMessages(nosologyData.getTherapy[activeStep].messages, MessageType.DESCRIPTION);
    };

    render() {
        const {
            classes,
            getRecomentTextsView,
        } = this.props;
        const {selGroupId} = this.state;

        // console.log('+++++++++ this.drugGroups:', this.drugGroups);
        // console.log('+++++++++ this.drugGroups.filter((drugGroup) => drugGroup.drugs.length > 0).length > 0:', this.drugGroups.filter((drugGroup) => drugGroup.drugs.length > 0).length > 0);

        return (
            <Box>
                {getRecomentTextsView && getRecomentTextsView()}
                <Box className={classes.contentBox}>
                    {(this.drugGroups && this.drugGroups.filter((drugGroup) => drugGroup.drugs.length > 0).length > 0) &&
                        <>
                            <Tabs
                                classes={{root: classes.root, scroller: classes.scroller}}
                                indicatorColor={"primary"}
                                textColor={"primary"}
                                value={selGroupId}
                                onChange={this.onTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                // centered
                            >
                                {this.drugGroups.filter((drugGroup) => drugGroup.drugs && drugGroup.drugs.length > 0).sort(sortByOrder).map(group => (
                                    <Tab key={group.id} label={group.shortName}/>
                                ))}
                            </Tabs>
                            <PanelDivider/>

                            {this.drugsView()}

                            <PanelDivider/>

                            <Grid container spacing={2}>
                                <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                    <Paper className={classes.contentBox} variant={"outlined"}>
                                        <HelpDrugsTextsView />
                                    </Paper>
                                </Grid>
                            </Grid>

                            {this.statisticsView()}
                        </>
                    }
                    {(!(this.drugGroups && this.drugGroups.filter((drugGroup) => drugGroup.drugs.length > 0).length > 0)) &&
                        <>
                            <Grid container spacing={2}>
                                <Grid item xl={12} lg={12} md={6} sm={12} xs={12}>
                                    <Paper className={classes.contentBox} variant={"outlined"}>
                                        {this.getNoTherapyMessage().messages.map((message, index) => (
                                            <Typography key={index}>{message.payload}</Typography>
                                        ))}
                                        {/*<Typography>*/}
                                        {/*    <FormattedMessage id="label.therapy.not_available"*/}
                                        {/*                      defaultMessage="Therapy is not indicated"/>*/}
                                        {/*</Typography>*/}
                                    </Paper>
                                </Grid>
                            </Grid>
                        </>
                    }
                </Box>
            </Box>
        );
    }
}

DyslipidemiaSelectDrugs.propTypes = {
    classes: PropTypes.object.isRequired,
    isPrescriptionReadonly: PropTypes.bool,
    getSteps: PropTypes.func.isRequired,
    activeStep: PropTypes.number,
    getTouched: PropTypes.func.isRequired,
    getRecomentTextsView: PropTypes.func,
    getTherapyGroupDrugs: PropTypes.func.isRequired,
    setTherapyGroupDrugs: PropTypes.func.isRequired,
    getStatistics: PropTypes.func.isRequired,
    setStatistics: PropTypes.func.isRequired,
    setDrugForTherapyGroup: PropTypes.func.isRequired,
    // getSelectTherapyComb: PropTypes.func.isRequired,
    // getSelectTherapyType: PropTypes.func.isRequired,
    // setForestPlotView: PropTypes.func.isRequired,
};

export default compose(
    withStyles(styles),
    injectIntl,
    withApollo,
)(DyslipidemiaSelectDrugs);
